import React from 'react'
import { X } from 'react-feather'
import { Modal } from 'react-bootstrap'
import { GrayButton } from '../../button/Index'

const Create = (props) => {
    return (
        <Modal
            {...props}
            size="lg"
            centered
            className="custom-modal"
        >
            <Modal.Header>
                <div className="d-flex">
                    <div><h6 className="mb-0">Create new product category</h6></div>
                    <div className="ml-auto">
                        <GrayButton
                            type="button"
                            onClick={props.onHide}
                            style={{ padding: "7px 10px", borderRadius: "50%" }}
                        ><X size={16} /></GrayButton>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>

            </Modal.Body>
        </Modal>
    );
}

export default Create;