
import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { Edit2, Plus, Printer, Trash2 } from 'react-feather'
import {
    GrayButton,
    SuccessButton,
    DangerButton
} from '../../../components/button/Index'
import { Images } from '../../../utils/Images'
import { Layout, Main } from '../../../components/layout/Index'
import { DataTable } from '../../../components/table/Index'
import CreateModal from '../../../components/modals/productCategory/Create'

const Category = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [totalRows, setTotalRows] = useState(0)
    const [perPage, setPerPage] = useState(10)
    const [searching, setSearching] = useState(false)
    const [isCreate, setCreate] = useState(false)

    const fetchUsers = useCallback(async (page) => {
        setLoading(true)
        const response = await axios.get(
            `https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`,
        )

        setData(response.data.data)
        setTotalRows(response.data.total)
        setLoading(false)
    }, [perPage])

    const handlePageChange = page => fetchUsers(page)

    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true)

        const response = await axios.get(`https://reqres.in/api/users?page=${page}&per_page=${newPerPage}&delay=1`)

        setData(response.data.data)
        setPerPage(newPerPage)
        setLoading(false)
    }

    useEffect(() => {
        fetchUsers(1)
    }, [fetchUsers])

    const columns = [
        {
            name: 'Image',
            grow: 0,
            cell: row => <img height="50px" width="50px" alt={row.avatar} src={row.avatar} />,
        },
        {
            name: 'Name',
            selector: row => row.first_name,
            sortable: true,
        },
        {
            name: 'Code',
            selector: row => row.last_name,
            sortable: true,
        },
        {
            name: 'Available',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Category',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Brand',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Purchase Price',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Sell Price',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Discount Type',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Discount Amount',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Warranty Type',
            selector: row => row.email,
            sortable: true,
        },

        {
            name: 'Action',
            minWidth: '150px',
            cell: row =>
                <div>
                    <GrayButton
                        style={{ borderRadius: "50%", padding: "6px 9px", marginRight: 5 }}
                        onClick={() => handleAction(row)}
                    >
                        <img src={Images.BarCode} alt="..." />
                    </GrayButton>
                    <SuccessButton
                        style={{ borderRadius: "50%", padding: "6px 9px", marginRight: 5 }}
                        onClick={() => handleAction(row)}
                    >
                        <Edit2 size={16} />
                    </SuccessButton>
                    <DangerButton
                        style={{ borderRadius: "50%", padding: "6px 9px" }}
                        onClick={() => handleAction(row)}
                    >
                        <Trash2 size={16} />
                    </DangerButton>
                </div>
        },
    ]

    // Handle action
    const handleAction = value => console.log(value);

    // Handle search
    const handleSearch = async query => {
        setSearching(true)
        console.log(query)

        setTimeout(() => {
            setSearching(false)
        }, 2000);
    }

    return (
        <div>
            <Layout
                page="inventory / category list"
                message="Category of product usually you sell."
                container="container-fluid"
                button={
                    <div>
                        <GrayButton
                            type="button"
                            onClick={() => setCreate(true)}
                        >
                            <Plus size={15} style={{ marginRight: 5 }} />
                            <span style={{ fontSize: 13 }}>ADD NEW</span>
                        </GrayButton>

                        <GrayButton className="ml-2 mt-2 mt-sm-0">
                            <Printer size={15} style={{ marginRight: 5 }} />
                            <span style={{ fontSize: 13 }}>PRINT</span>
                        </GrayButton>
                    </div>
                }
            />

            <Main>
                <div className="col-12">
                    <DataTable
                        columns={columns}
                        data={data}
                        loading={loading}
                        totalRows={totalRows}
                        handlePerRowsChange={handlePerRowsChange}
                        handlePageChange={handlePageChange}
                        searchable
                        search={handleSearch}
                        searching={searching}
                    />
                </div>
            </Main>

            {/* Product category create */}
            <CreateModal
                show={isCreate}
                onHide={() => setCreate(false)}
            />
        </div>
    );
}

export default Category;