import React, { useState } from 'react'
import swal from 'sweetalert'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { PrimaryButton } from '../../button/Index'

const Index = () => {
    const { t } = useTranslation()
    const { register, handleSubmit, formState: { errors } } = useForm()
    const [isLoading, setLoading] = useState(false)

    // Submit Form
    const onSubmit = async (data) => {
        try {
            setLoading(true)
            console.log(data)

            setTimeout(() => {
                setLoading(false)
                swal({
                    title: "Successfully!",
                    text: `Saved your location settings.`,
                    icon: "success",
                    button: false,
                })
            }, 1000)
        } catch (error) {
            if (error) console.log(error)
        }
    }

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>

                {/* Street Address */}
                <div className="form-group mb-4">
                    {errors.streetAddress && errors.streetAddress.message ?
                        <small className="text-danger">{errors.streetAddress && errors.streetAddress.message}</small>
                        : <small>{t('Street address')}</small>}

                    <input
                        type="text"
                        className={errors.streetAddress ? "form-control shadow-none error" : "form-control shadow-none"}
                        placeholder={t("Street address")}
                        {...register("streetAddress", { required: t("Street address is required") })}
                    />
                </div>

                {/* Post office */}
                <div className="form-group mb-4">
                    {errors.postOffice && errors.postOffice.message ?
                        <small className="text-danger">{errors.postOffice && errors.postOffice.message}</small>
                        : <small>{t('Post office')}</small>}

                    <input
                        type="text"
                        className={errors.postOffice ? "form-control shadow-none error" : "form-control shadow-none"}
                        placeholder={t("Post office")}
                        {...register("postOffice", { required: t("Post office is required") })}
                    />
                </div>

                {/* ZIP/Post code */}
                <div className="form-group mb-4">
                    {errors.zipPostCode && errors.zipPostCode.message ?
                        <small className="text-danger">{errors.zipPostCode && errors.zipPostCode.message}</small>
                        : <small>{t('ZIP / Post code')}</small>}

                    <input
                        type="text"
                        className={errors.zipPostCode ? "form-control shadow-none error" : "form-control shadow-none"}
                        placeholder="ZIP / Post code"
                        {...register("zipPostCode", { required: t("ZIP / Post code is required") })}
                    />
                </div>

                {/* Upzila */}
                <div className="form-group mb-4">
                    {errors.upzila && errors.upzila.message ?
                        <small className="text-danger">{errors.upzila && errors.upzila.message}</small>
                        : <small>{t('Upzila')}</small>}

                    <input
                        type="text"
                        className={errors.upzila ? "form-control shadow-none error" : "form-control shadow-none"}
                        placeholder="Upzila"
                        {...register("upzila", { required: t("Upzila is required") })}
                    />
                </div>

                {/* District / City */}
                <div className="form-group mb-4">
                    {errors.districtCity && errors.districtCity.message ?
                        <small className="text-danger">{errors.districtCity && errors.districtCity.message}</small>
                        : <small>{t('District / City')}</small>}

                    <input
                        type="text"
                        className={errors.districtCity ? "form-control shadow-none error" : "form-control shadow-none"}
                        placeholder="District / City"
                        {...register("districtCity", { required: t("District / City is required") })}
                    />
                </div>

                {/* Division / State */}
                <div className="form-group mb-4">
                    {errors.divisionState && errors.divisionState.message ?
                        <small className="text-danger">{errors.divisionState && errors.divisionState.message}</small>
                        : <small>{t('Division / State')}</small>}

                    <input
                        type="text"
                        className={errors.divisionState ? "form-control shadow-none error" : "form-control shadow-none"}
                        placeholder="Division / State"
                        {...register("divisionState", { required: t("Division / State is required") })}
                    />
                </div>

                <div className="text-right">
                    <PrimaryButton
                        type="submit"
                        className="px-4"
                        disabled={isLoading}
                    >{isLoading ? t("Saving ...") : t("Save Changes")}</PrimaryButton>
                </div>
            </form>
        </div>
    );
}

export default Index;