import React, { useState } from 'react'
import swal from 'sweetalert'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { PrimaryButton } from '../../button/Index'
import { FileUploader } from '../../fileUploader/Index'
import { isValidEmail, isValidPhone } from '../../../utils/_heplers'

const Index = () => {
    const { t } = useTranslation()
    const { register, handleSubmit, formState: { errors } } = useForm()
    const [logo, setLogo] = useState({ value: null, error: null })
    const [isLoading, setLoading] = useState(false)

    // Submit Form
    const onSubmit = async (data) => {
        try {
            if (!logo.value) return setLogo({ value: null, error: 'Logo is required.' })

            setLoading(true)
            let formData = new FormData()
            formData.append('name', data.name)
            formData.append('email', data.email)
            formData.append('phone', data.phone)
            formData.append('logo', logo.value)

            setTimeout(() => {
                setLoading(false)
                swal({
                    title: "Successfully!",
                    text: `Saved your general settings.`,
                    icon: "success",
                    button: false,
                })
            }, 1000)
        } catch (error) {
            if (error) console.log(error)
        }
    }

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>

                {/* Dokan Name */}
                <div className="form-group mb-4">
                    {errors.dokanName && errors.dokanName.message ?
                        <small className="text-danger">{errors.dokanName && errors.dokanName.message}</small>
                        : <small>{t('Dokan name')}</small>}

                    <input
                        type="text"
                        className={errors.dokanName ? "form-control shadow-none error" : "form-control shadow-none"}
                        placeholder={t("Dokan name")}
                        {...register("dokanName", { required: t("Dokan name is required") })}
                    />
                </div>

                {/* E-mail */}
                <div className="form-group mb-4">
                    {errors.email && errors.email.message ?
                        <small className="text-danger">{errors.email && errors.email.message}</small>
                        : <small>{t('E-mail')}</small>}

                    <input
                        type="text"
                        className={errors.email ? "form-control shadow-none error" : "form-control shadow-none"}
                        placeholder="example@gmail.com"
                        {...register("email", {
                            required: t("E-mail is required"),
                            pattern: {
                                value: isValidEmail(),
                                message: "Invalid e-mail address"
                            }
                        })}
                    />
                </div>

                {/* Phone */}
                <div className="form-group mb-4">
                    {errors.phone && errors.phone.message ?
                        <small className="text-danger">{errors.phone && errors.phone.message}</small>
                        : <small>{t('Phone')}</small>}

                    <input
                        type="text"
                        className={errors.phone ? "form-control shadow-none error" : "form-control shadow-none"}
                        placeholder="01XXXXXXXXX"
                        {...register("phone", {
                            required: t("Phone number is required"),
                            pattern: {
                                value: isValidPhone(),
                                message: "Invalid phone number"
                            }
                        })}
                    />
                </div>

                {/* File uploader */}
                <FileUploader
                    width={80}
                    height={80}
                    limit={100}
                    title={t("Logo")}
                    error={logo.error}
                    dataHandeller={(data) => setLogo({ ...logo, value: data.image || null, error: data.error || null })}
                />

                <div className="text-right">
                    <PrimaryButton
                        type="submit"
                        className="px-4"
                        disabled={isLoading}
                    >{isLoading ? t("Saving ...") : t("Save Changes")}</PrimaryButton>
                </div>
            </form>
        </div>
    );
}

export default Index;