import React, { useEffect, useState } from 'react'
import './style.scss'
import { useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'

import { Images } from '../../utils/Images'
import { PrimaryButton } from '../../components/button/Index'
import Image from '../../components/image/Index'
import { useTranslation } from 'react-i18next'

const Login = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const { register, handleSubmit, formState: { errors } } = useForm()
    const [isLogging, setLogging] = useState(false)

    useEffect(() => {
        const token = localStorage.getItem('token')
        if (token) history.push('/dashboard')
    }, [history])

    // Submit Form
    const onSubmit = async (data) => {
        setLogging(true)
        console.log(data)
        setLogging(false)
    }

    return (
        <div className="auth-container">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="card shadow-none border-0">
                            <div className="card-header bg-white px-0">
                                <div className="text-center mb-4">
                                    <Image
                                        x={90}
                                        y={90}
                                        src={Images.Logo}
                                        alt="Company Logo"
                                    />
                                </div>
                                <h4 className="mb-2">{t("Forgot Password?")}</h4>
                                <p className="mb-0">Just enter your E-mail / Phone, we will sent new password.</p>
                            </div>
                            <div className="card-body px-0">
                                <form onSubmit={handleSubmit(onSubmit)}>

                                    {/* E-mail */}
                                    <div className="form-group mb-4">
                                        {errors.emailPhone && errors.emailPhone.message ? (
                                            <small className="text-danger">{errors.emailPhone && errors.emailPhone.message}</small>
                                        ) : <small>{t("E-mail / Phone")}</small>}

                                        <input
                                            type="text"
                                            className={errors.emailPhone ? "form-control shadow-none error" : "form-control shadow-none"}
                                            placeholder="example@gmail.com / 01xxxxxxxxx"
                                            {...register("emailPhone", { required: t("E-mail or Phone is required") })}
                                        />
                                    </div>

                                    <PrimaryButton
                                        type="submit"
                                        style={{ width: "100%" }}
                                        disabled={isLogging}
                                    >{isLogging ? t("Loading ...") : t("Submit")}</PrimaryButton>

                                    <div className="mt-2 text-right">
                                        <p>{t("Back to")} <Link to="/">{t("Sign In")}</Link></p>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;