import React, { useState } from 'react'
import './style.scss'
import swal from 'sweetalert'
import { Form } from 'react-bootstrap'
import { Monitor, Smartphone } from 'react-feather'
import { SuccessButton } from '../../button/Index'
import PasswordChangeModal from '../../modals/settings/password/Index'
import { loggedInDevices } from '../../../utils/data'

const Index = () => {
    const [show, setShow] = useState({ status: false, loading: false })
    const options = [
        { enabled: true, value: "towFactorAuth", label: "Two-factor Authentication." },
        { enabled: true, value: "unauthorizedLogin", label: "Get alerts of unauthorized login." }
    ]

    // Handle option
    const handleOption = item => {
        console.log(item)
    }

    // Update password
    const updatePassword = async data => {
        try {
            setShow({ ...show, loading: true })
            console.log(data)

            setTimeout(() => {
                setShow({ status: false, loading: false })
                swal({
                    title: "Successfully!",
                    text: `Your password updated.`,
                    icon: "success",
                    button: false,
                })
            }, 1000)
        } catch (error) {
            if (error) console.log(error)
        }
    }

    return (
        <div className="security-login-container">

            {/* Loggedin devices container */}
            <h6 className="mb-3" style={{ fontSize: 14 }}><b>Where you were loggedin</b></h6>
            {loggedInDevices && loggedInDevices.map((item, i) =>
                <div className="device-container d-flex border-bottom" key={i}>
                    <div>{item.type === "Mobile" ? <Smartphone size={20} /> : <Monitor size={20} />}</div>
                    <div className="pl-3">
                        <p className="text-capitalize mb-0">{item.device}</p>
                        <small className="text-capitalize">{item.location}</small>
                    </div>
                </div>
            )}

            {/* Change password */}
            <div className="d-flex mt-4">
                <div><h6 className="mb-3 mt-2" style={{ fontSize: 14 }}><b>Login</b></h6></div>
                <div className="ml-auto">
                    <SuccessButton
                        type="button"
                        onClick={() => setShow({ ...show, status: true })}
                    >Change Password</SuccessButton>
                </div>
            </div>

            {/* Options */}
            <div className="mt-4">
                {options && options.map((item, i) =>
                    <Form.Check
                        key={i}
                        type="switch"
                        className="mb-3"
                        style={{ fontSize: 14 }}
                        id={`${item.label}-switch`}
                        label={item.label}
                        defaultChecked={item.enabled}
                        onChange={() => handleOption(item)}
                    />
                )}
            </div>

            {/* Password change modal */}
            <PasswordChangeModal
                show={show.status}
                loading={show.loading}
                submit={updatePassword}
                onHide={() => setShow({ show: false, loading: false })}
            />
        </div>
    );
}

export default Index;