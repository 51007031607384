import React, { useEffect, useState } from 'react'
import './style.scss'
import { Link } from 'react-router-dom'
import { Tabs, Tab } from 'react-bootstrap'
import { ChevronLeft } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { GrayButton } from '../../components/button/Index'
import { Layout, Main } from '../../components/layout/Index'
import { Loader } from '../../components/loading/Index'
import { ShortName } from '../../components/shortName/Index'
import { PurchaseHistory } from '../../components/customer/PurchaseHistory'
import { DueHistory } from '../../components/customer/DueHistory'
import { NoticeHistory } from '../../components/customer/NoticeHistory'

const Show = () => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 1500)
    }, [])

    if (loading) return <Loader />

    return (
        <div>
            <Layout
                page={t("dashboard / customer")}
                message={t("Antooba's information.")}
                container="container-fluid"
                button={
                    <div>
                        <Link to="/dashboard/customer">
                            <GrayButton type="button">
                                <ChevronLeft size={15} style={{ marginRight: 5 }} />
                                <span style={{ fontSize: 13 }}>BACK</span>
                            </GrayButton>
                        </Link>
                    </div>
                }
            />

            <Main>

                {/* Basic information of customer */}
                <div className="col-12">
                    <div className="d-lg-flex">

                        {/* Vector */}
                        <div style={{ width: 100 }}>
                            <ShortName
                                x={70}
                                y={70}
                                size={35}
                                name={'Antooba'}
                            />
                        </div>

                        {/* Personal info */}
                        <div className="ml-lg-4 mt-4 mt-lg-0">
                            <h6 className="text-capitalize">antooba corp.</h6>
                            <table className="table table-sm table-borderless">
                                <tbody>
                                    <tr>
                                        <td style={{ width: 80 }}><p className="mb-0">E-mail</p></td>
                                        <td><p className="text-lowercase mb-0">: antooba@gmail.com</p></td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: 80 }}><p className="mb-0">Phone</p></td>
                                        <td><p className="mb-0">: 01XXXXXXXXX</p></td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: 80 }}><p className="mb-0">Registered</p></td>
                                        <td><p className="mb-0">: 10 May, 2021</p></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        {/* Sales info */}
                        <div className="ml-lg-4 mt-lg-4 pt-lg-1">
                            <table className="table table-sm table-borderless">
                                <tbody>
                                    <tr>
                                        <td style={{ width: 80 }}><p className="mb-0">Total sale</p></td>
                                        <td><p className="mb-0">: Tk. 5000</p></td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: 80 }}><p className="mb-0">Total due</p></td>
                                        <td><p className="mb-0">: Tk. 5000</p></td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: 80 }}><p className="mb-0">Last payback</p></td>
                                        <td><p className="mb-0">: Tk. 5000 (10 Jun, 2021)</p></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                {/* History tabs */}
                <div className="col-12 mt-2 mt-lg-4">
                    <Tabs defaultActiveKey="purchase" id="uncontrolled-tab" className="mb-3">
                        <Tab eventKey="purchase" title="Purchase History">
                            <PurchaseHistory />
                        </Tab>
                        <Tab eventKey="due" title="Due History">
                            <DueHistory />
                        </Tab>
                        <Tab eventKey="notice" title="Notice">
                            <NoticeHistory />
                        </Tab>
                    </Tabs>
                </div>
            </Main>
        </div>
    );
}

export default Show;