import React, { useState } from 'react'
import { Globe } from 'react-feather'
import { useTranslation } from 'react-i18next'

const Index = () => {
    const { i18n } = useTranslation()
    const [selectedLang, setSelectedLang] = useState(localStorage.getItem('language'));
    const changeLanguage = event => {
        setSelectedLang(event.target.value)
        i18n.changeLanguage(event.target.value)
        localStorage.setItem('language',event.target.value)
    }
    return (
        <div className="language-selector-container" onChange={changeLanguage}>
            <div className="form-group d-flex pt-1 pr-2 m-0">
                <div className="pt-1"><Globe size={20} /></div>
                <div>
                    <select
                        className="form-control shadow-none border-0 p-0"
                        defaultValue={selectedLang}
                        style={{ fontSize: 14, fontWeight: 400 }}
                    >
                        <option value="en">English</option>
                        <option value="bn">বাংলা</option>
                    </select>
                </div>
            </div>
        </div>
    );
}

export default Index;