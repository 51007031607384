import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { PrimaryButton } from '../button/Index'
import SingleSelect from '../select/Single'

const Product = (props) => {
    const { register, handleSubmit, formState: { errors } } = useForm()
    const [supplier, setSupplier] = useState({ value: null, error: null })
    const [category, setCategory] = useState(null)
    const [brand, setBrand] = useState(null)
    const [warranty, setWarranty] = useState({
        options: [
            { label: "No warranty", value: "No warranty" },
            { label: "Lifetime", value: "Lifetime" },
            { label: "Monthly", value: "Monthly" }
        ],
        value: null
    })

    // Submit Form
    const onSubmit = data => {
        if (!supplier.value) return setSupplier({ value: null, error: "Supplier is required" })
        const formData = {
            ...data,
            brand,
            category,
            supplier: supplier.value,
            warranty: warranty.value
        }

        props.submit(formData)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">

                {/* Number of code */}
                <div className="col-12">
                    <div className="form-group mb-4">
                        {errors.numberOfCode && errors.numberOfCode.message ?
                            <small className="text-danger">{errors.numberOfCode && errors.numberOfCode.message}</small>
                            : <small>How many code you want to scan ? <span className="text-danger">*</span></small>}

                        <input
                            type="number"
                            className={errors.numberOfCode ? "form-control shadow-none error" : "form-control shadow-none"}
                            placeholder="Number of code"
                            {...register("numberOfCode", { required: "Total code is required" })}
                        />
                    </div>
                </div>

                {/* Name */}
                <div className="col-12 col-lg-8">
                    <div className="form-group mb-4">
                        {errors.name && errors.name.message ?
                            <small className="text-danger">{errors.name && errors.name.message}</small>
                            : <small>Name ? <span className="text-danger">*</span></small>}

                        <input
                            type="text"
                            className={errors.name ? "form-control shadow-none error" : "form-control shadow-none"}
                            placeholder="Enter product name"
                            {...register("name", { required: "Name is required" })}
                        />
                    </div>
                </div>

                {/* Supplier */}
                <div className="col-12 col-lg-4">
                    <div className="form-group mb-4">
                        {supplier.error ?
                            <small className="text-danger">{supplier.error}</small>
                            : <small>Supplier <span className="text-danger">*</span></small>}

                        <SingleSelect
                            error={supplier.error}
                            placeholder=" a supplier"
                            options={props.suppliers}
                            value={event => setSupplier({ value: event.value, error: null })}
                        />
                    </div>
                </div>

                {/* Category */}
                <div className="col-12 col-lg-6">
                    <div className="form-group mb-4">
                        <small>Category</small>

                        <SingleSelect
                            placeholder=" a category"
                            options={props.categories}
                            value={event => setCategory(event.value)}
                        />
                    </div>
                </div>

                {/* Brand */}
                <div className="col-12 col-lg-6">
                    <div className="form-group mb-4">
                        <small>Brand</small>

                        <SingleSelect
                            placeholder=" a brand"
                            options={props.brands}
                            value={event => setBrand(event.value)}
                        />
                    </div>
                </div>

                {/* Last purchase boucher code */}
                <div className="col-12 col-lg-6">
                    <div className="form-group mb-4">
                        <small>Last purchase boucher code</small>

                        <input
                            type="text"
                            className={errors.lastPurchaseBoucherCode ? "form-control shadow-none error" : "form-control shadow-none"}
                            placeholder="Enter voucher code"
                            {...register("lastPurchaseBoucherCode")}
                        />
                    </div>
                </div>

                {/* Number of product */}
                <div className="col-12 col-lg-6">
                    <div className="form-group mb-4">
                        {errors.numberOfProduct && errors.numberOfProduct.message ?
                            <small className="text-danger">{errors.numberOfProduct && errors.numberOfProduct.message}</small>
                            : <small>Number of product you have <span className="text-danger">*</span></small>}

                        <input
                            type="number"
                            className={errors.numberOfProduct ? "form-control shadow-none error" : "form-control shadow-none"}
                            placeholder="Enter number of product"
                            defaultValue={0}
                            {...register("numberOfProduct", { required: "Specify the number of product" })}
                        />
                    </div>
                </div>

                {/* Purchase price */}
                <div className="col-12 col-lg-4">
                    <div className="form-group mb-4">
                        {errors.purchasePrice && errors.purchasePrice.message ?
                            <small className="text-danger">{errors.purchasePrice && errors.purchasePrice.message}</small>
                            : <small>Purchase price <span className="text-danger">*</span></small>}

                        <input
                            type="number"
                            className={errors.purchasePrice ? "form-control shadow-none error" : "form-control shadow-none"}
                            placeholder="Purchase price"
                            {...register("purchasePrice", { required: "Purchase price is required" })}
                        />
                    </div>
                </div>

                {/* Sell price */}
                <div className="col-12 col-lg-4">
                    <div className="form-group mb-4">
                        {errors.sellPrice && errors.sellPrice.message ?
                            <small className="text-danger">{errors.sellPrice && errors.sellPrice.message}</small>
                            : <small>Sell price <span className="text-danger">*</span></small>}

                        <input
                            type="number"
                            className={errors.sellPrice ? "form-control shadow-none error" : "form-control shadow-none"}
                            placeholder="Sell price"
                            {...register("sellPrice", { required: "Sell price is required" })}
                        />
                    </div>
                </div>

                {/* Warranty type */}
                <div className="col-12 col-lg-4">
                    <div className="form-group mb-4">
                        <small>Warranty type</small>

                        <SingleSelect
                            placeholder=" warranty type"
                            options={warranty.options}
                            value={event => setWarranty({ value: event.value, error: null })}
                        />
                    </div>
                </div>

                {/* Description */}
                <div className="col-12">
                    <div className="form-group mb-4">
                        <small>Description</small>

                        <textarea
                            rows={5}
                            className="form-control shadow-none"
                            placeholder="Write product description"
                            {...register("description")}
                        />
                    </div>
                </div>

                <div className="col-12 text-right">
                    <PrimaryButton
                        type="submit"
                        className="px-4"
                        disabled={props.loading}
                    >
                        <span>{props.loading ? "Creating ..." : "Create"}</span>
                    </PrimaryButton>
                </div>
            </div>
        </form>
    );
}

export default Product;