import React, { useState } from 'react'
import './style.scss'
import { Link } from 'react-router-dom'
import { Edit, Plus, ShoppingCart, Trash2 } from 'react-feather'
import { Layout } from '../../components/layout/Index'
import { FloatButton } from '../../components/help/Index'
import { englishToBengali } from '../../utils/_heplers'
import {
    DangerButton,
    GrayButton,
    PrimaryOutlineBadgeButton,
} from '../../components/button/Index'
import Navbar from '../../components/navbar/Index'
import EditModal from '../../components/modals/shop/Edit'
import CreateModal from '../../components/modals/shop/Create'
import DeleteModal from '../../components/modals/delete/Index'
import { shops } from '../../utils/data'
import { useTranslation } from 'react-i18next'

const Index = () => {
    const { t } = useTranslation()
    const [show, setShow] = useState(false)
    const [isDelete, setDelete] = useState({ value: null, show: false, loading: false })
    const [isEdit, setEdit] = useState({ value: null, show: false, loading: false })

    // Handle delete
    const handleDelete = () => {
        setDelete({ ...isDelete, loading: true })
        setTimeout(() => {
            setDelete({ ...isDelete, show: false, loading: false })
        }, 1000)
    }

    return (
        <div className="shop-container">
            <Navbar menu={false} />
            <div style={{ paddingTop: 63 }} />
            <Layout
                page={t('shop list')}
                message={t('Welcome to shop list')}
                container="container"
                button={
                    <div className="print-hidden">
                        <Link to="/shop">
                            <PrimaryOutlineBadgeButton
                                type="button"
                                className="px-4"
                                badgeValue={englishToBengali(15)}
                            >
                                <ShoppingCart size={17} style={{ marginRight: 5 }} />
                                <span style={{ fontSize: 13 }}>{t('E-COMMERCE')}</span>
                            </PrimaryOutlineBadgeButton>
                        </Link>
                    </div>
                }
            />

            <div className="container">
                <div className="row">
                    <div className="col-12">

                        {shops && shops.length ?
                            shops.map((shop, i) =>
                                <div className="card item-card" key={i}>
                                    <div className="card-body">
                                        <Link to={`/dashboard/${shop._id}`}><h5 className="text-capitalize mb-2">{shop.name}</h5></Link>
                                        <p className="text-uppercase mb-0">{t('shop id')}: {englishToBengali(12345678000)}</p>
                                        <div className="text-right">
                                            <DangerButton
                                                type="button"
                                                style={{ padding: "7px 10px", borderRadius: "50%", marginRight: 5 }}
                                                onClick={() => setDelete({ value: shop, show: true })}
                                            ><Trash2 size={16} /></DangerButton>

                                            <GrayButton
                                                type="button"
                                                style={{ padding: "7px 10px", borderRadius: "50%" }}
                                                onClick={() => setEdit({ value: shop, show: true })}
                                            ><Edit size={16} /></GrayButton>
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                        <div className="card item-card create-card" onClick={() => setShow(true)}>
                            <div className="card-body flex-center flex-column">
                                <Plus size={18} />
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/* Create shop */}
            <CreateModal
                show={show}
                onHide={() => setShow(false)}
            />

            {/* Edit shop */}
            <EditModal
                show={isEdit.show}
                shop={isEdit.value}
                onHide={() => setEdit({ value: null, loading: false, show: false })}
            />

            {/* Delete confirmation modal */}
            <DeleteModal
                show={isDelete.show}
                loading={isDelete.loading}
                message={<h6>{t("Want to delete shop?", {shop_name: isDelete.value ? isDelete.value.name : null})}</h6>}
                onHide={() => setDelete({ value: null, show: false, loading: false })}
                doDelete={handleDelete}
            />


            <FloatButton />
        </div>
    );
}

export default Index;
