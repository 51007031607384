
import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import swal from 'sweetalert'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { DollarSign, Edit2, Eye, Plus, Printer, Trash2 } from 'react-feather'
import {
    DangerButton,
    GrayButton,
    SuccessButton
} from '../../components/button/Index'
import { Layout, Main } from '../../components/layout/Index'
import { DataTable } from '../../components/table/Index'
import { Requests } from '../../utils/Http/Index'
import DeleteModal from '../../components/modals/delete/Index'
import CreateModal from '../../components/modals/customer/Create'

const Index = () => {
    const { t } = useTranslation()
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [totalRows, setTotalRows] = useState(0)
    const [perPage, setPerPage] = useState(10)
    const [searching, setSearching] = useState(false)
    const [isDelete, setDelete] = useState({ value: null, show: false, loading: false })
    const [isCreate, setCreate] = useState({ show: false, loading: false })

    const fetchUsers = useCallback(async (page) => {
        setLoading(true)
        const response = await axios.get(`https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`,)

        setData(response.data.data)
        setTotalRows(response.data.total)
        setLoading(false)
    }, [perPage])

    const handlePageChange = page => fetchUsers(page)
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true)
        const response = await axios.get(`https://reqres.in/api/users?page=${page}&per_page=${newPerPage}&delay=1`)

        setData(response.data.data)
        setPerPage(newPerPage)
        setLoading(false)
    };

    useEffect(() => {
        fetchUsers(1)
    }, [fetchUsers])

    const columns = [
        {
            name: `${t('SL')}`,
            selector: row => row.id,
            sortable: true,
            grow: 0
        },
        {
            name: `${t('Name')}`,
            selector: row => row.first_name,
            sortable: true
        },
        {
            name: `${t('Phone')}`,
            selector: row => row.first_name,
            sortable: true,
        },
        {
            name: `${t('Total Purchase (Tk)')}`,
            selector: row => row.id,
            sortable: true,
        },
        {
            name: `${t('Total Due (Tk)')}`,
            selector: row => row.id,
            sortable: true,
        },
        {
            name: `${t('Action')}`,
            grow: 0,
            minWidth: "200px",
            cell: row =>
                <div>
                    <GrayButton
                        type="button"
                        style={{ borderRadius: "50%", padding: "6px 9px", marginRight: 5 }}
                        onClick={() => handleAction(row)}
                    ><DollarSign size={16} />
                    </GrayButton>

                    <Link to={`/dashboard/customer/show/${row.id}`}>
                        <SuccessButton
                            type="button"
                            style={{ borderRadius: "50%", padding: "6px 9px", marginRight: 5 }}
                            onClick={() => handleAction(row)}
                        ><Eye size={16} />
                        </SuccessButton>
                    </Link>

                    <SuccessButton
                        type="button"
                        style={{ borderRadius: "50%", padding: "6px 9px", marginRight: 5 }}
                        onClick={() => handleAction(row)}
                    ><Edit2 size={16} />
                    </SuccessButton>
                    <DangerButton
                        type="button"
                        style={{ borderRadius: "50%", padding: "6px 9px", marginRight: 5 }}
                        onClick={() => setDelete({ value: row, show: true })}
                    ><Trash2 size={16} />
                    </DangerButton>
                </div>
        }
    ]

    // Handle action
    const handleAction = value => console.log(value);

    // Handle search suggestion
    const handleSuggestion = async (value) => {
        let data = {
            results: null,
            message: null
        }
        const response = await Requests.Search.SearchSuggestion(value)

        if (response && response.length) data.results = response
        else data.message = t("No results found")
        return data
    }

    // Handle search
    const handleSearch = async query => {
        setSearching(true)
        console.log(query)

        setTimeout(() => {
            setSearching(false)
        }, 2000)
    }

    // Handle delete
    const handleDelete = async () => {
        setDelete({ ...isDelete, loading: true })
        console.log(isDelete.value)
        setTimeout(() => {
            setDelete({ ...isDelete, show: false, loading: false })
            swal({
                title: t("Successfully!"),
                text: t('Customer deleted.'),
                icon: "success",
                button: false,
            })
        }, 1000)
    }

    // Handle create
    const handleCreate = async (data) => {
        try {
            setCreate({ ...isCreate, loading: true })
            console.log(data)

            setTimeout(() => {
                setCreate({ show: false, loading: false })
                swal({
                    title: t("Successfully!"),
                    text: t('Customer created.'),
                    icon: "success",
                    button: false,
                })
            }, 1000)
        } catch (error) {
            if (error) console.log(error)
        }
    }

    return (
        <div>
            <Layout
                page={t("dashboard / customers")}
                message={t("Who are using this system.")}
                container="container-fluid"
                button={
                    <div>
                        <GrayButton
                            type="button"
                            onClick={() => setCreate({ ...isCreate, show: true })}
                        >
                            <Plus size={15} style={{ marginRight: 5 }} />
                            <span style={{ fontSize: 13 }}>{t('ADD NEW')}</span>
                        </GrayButton>
                        <GrayButton
                            type="button"
                            className="ml-2 mt-2 mt-sm-0"
                            onClick={() => console.log("Will print")}
                        >
                            <Printer size={15} style={{ marginRight: 5 }} />
                            <span style={{ fontSize: 13 }}>{t('PRINT')}</span>
                        </GrayButton>
                    </div>
                }
            />

            <Main>
                <div className="col-12">
                    <DataTable
                        columns={columns}
                        data={data}
                        loading={loading}
                        totalRows={totalRows}
                        handlePerRowsChange={handlePerRowsChange}
                        handlePageChange={handlePageChange}
                        searchable
                        search={handleSearch}
                        searching={searching}
                        suggestion={handleSuggestion}
                    />
                </div>
            </Main>

            {/* Delete confirmation modal */}
            <DeleteModal
                show={isDelete.show}
                loading={isDelete.loading}
                message={<h6>{t("Want to delete")} {isDelete.value ? isDelete.value.email : null} ?</h6>}
                onHide={() => setDelete({ value: null, show: false, loading: false })}
                doDelete={handleDelete}
            />

            {/* Create modal */}
            <CreateModal
                show={isCreate.show}
                loading={isCreate.loading}
                submit={handleCreate}
                onHide={() => setCreate({ show: false, loading: false })}
            />
        </div>
    );
}

export default Index;
