import React from 'react'
import './style.scss'
import { Link } from 'react-router-dom'
import { Bell, Menu } from 'react-feather'
import Dropdown from './Dropdown'
import Image from '../image/Index'

import { Images } from '../../utils/Images'
import { NotificationButton } from '../button/Index'
import LanguageSelector from '../../components/languageSelector/Index'
import { englishToBengali } from '../../utils/_heplers'

const Index = (props) => {
    return (
        <div className="navbar-container">
            <div className="d-flex">
                <div><Image src={Images.Logo} alt="Company logo" x={42} y={42} /></div>
                <div><h6 className="text-capitalize mb-0">dokan 1</h6></div>
                <div className="ml-auto">
                    <Link to="/shop/">
                        <NotificationButton
                            type="button"
                            className="mr-2 mt-1"
                            badgeValue={englishToBengali(9)}
                        >
                            <Bell size={20} />
                        </NotificationButton>
                    </Link>
                </div>
                <div><LanguageSelector /></div>
                <div><Dropdown /></div>

                {props.menu &&
                    <div className="d-lg-none pl-2">
                        <button
                            type="button"
                            className="btn shadow-none rounded-circle"
                            onClick={props.drawer}
                        >
                            <Menu size={24} />
                        </button>
                    </div>
                }
            </div>
        </div>
    )
}

export default Index;