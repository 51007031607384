import React from 'react'
import { X } from 'react-feather'
import { Modal } from 'react-bootstrap'
import StepWizard from 'react-step-wizard'
import { Step1, Step2 } from './Step'
import { GrayButton } from '../../button/Index'
import { useTranslation } from 'react-i18next'

const Create = (props) => {
    const { t } = useTranslation()
    return (
        <Modal
            {...props}
            size="md"
            centered
            className="custom-modal"
        >
            <Modal.Header>
                <div className="d-flex">
                    <div><h6 className="mb-0">{t('create shop')}</h6></div>
                    <div className="ml-auto">
                        <GrayButton
                            type="button"
                            onClick={props.onHide}
                            style={{ padding: "7px 10px", borderRadius: "50%" }}
                        ><X size={16} /></GrayButton>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body style={{ overflow: "hidden" }}>
                <StepWizard>
                    <Step1 initialStep={1} />
                    <Step2 onHide={props.onHide} />
                </StepWizard>
            </Modal.Body>
        </Modal>
    );
}

export default Create;