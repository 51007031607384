
import React from 'react'
import ReactDataTable from 'react-data-table-component'
import { SearchComponent } from '../search/Index'

export const DataTable = (props) => {
    return (
        <ReactDataTable
            columns={props.columns}
            data={props.data}
            progressPending={props.loading}
            pagination
            paginationServer
            paginationTotalRows={props.totalRows}
            onChangeRowsPerPage={props.handlePerRowsChange}
            onChangePage={props.handlePageChange}
            subHeader={props.searchable}
            subHeaderComponent={
                <SearchComponent
                    placeholder="..."
                    loading={props.searching}
                    search={query => props.search(query)}
                    suggestion={props.suggestion}
                />}
        />
    );
};
