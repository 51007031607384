export const shops = [
    { _id: 111, name: "antooba shop 1" },
    { _id: 112, name: "antooba shop 2" },
    { _id: 113, name: "antooba shop 3" },
    { _id: 114, name: "antooba shop 4" },
    { _id: 115, name: "antooba shop 5" },
    { _id: 116, name: "antooba shop 6" },
    { _id: 117, name: "antooba shop 7" }
]

export const suppliers = [
    { label: "Antooba 1", value: "antooba 1" },
    { label: "Antooba 2", value: "antooba 2" },
    { label: "Antooba 3", value: "antooba 3" },
    { label: "Antooba 4", value: "antooba 4" },
    { label: "Antooba 5", value: "antooba 5" },
    { label: "Antooba 6", value: "antooba 6" },
    { label: "Antooba 7", value: "antooba 7" }
]

export const brands = [
    { label: "Antooba Brand 1", value: "antooba brand 1" },
    { label: "Antooba Brand 2", value: "antooba brand 2" },
    { label: "Antooba Brand 3", value: "antooba brand 3" },
    { label: "Antooba Brand 4", value: "antooba brand 4" },
    { label: "Antooba Brand 5", value: "antooba brand 5" },
    { label: "Antooba Brand 6", value: "antooba brand 6" },
    { label: "Antooba Brand 7", value: "antooba brand 7" }
]

export const categories = [
    { label: "Antooba category 1", value: "antooba category 1" },
    { label: "Antooba category 2", value: "antooba category 2" },
    { label: "Antooba category 3", value: "antooba category 3" },
    { label: "Antooba category 4", value: "antooba category 4" },
    { label: "Antooba category 5", value: "antooba category 5" },
    { label: "Antooba category 6", value: "antooba category 6" },
    { label: "Antooba category 7", value: "antooba category 7" }
]

export const loggedInDevices = [
    { type: "Mobile", device: "Realme XT", location: "Khaga, Bangladesh" },
    { type: "Mobile", device: "Realme A3", location: "Khaga, Bangladesh" },
    { type: "Desktop", device: "Chrome", location: "Khaga, Bangladesh" },
    { type: "Mobile", device: "Realme XT", location: "Khaga, Bangladesh" },
    { type: "Mobile", device: "Realme XT", location: "Khaga, Bangladesh" }
]
