import React from 'react'
import { Form } from 'react-bootstrap'

const Index = () => {
    const options = [
        { enabled: true,  value: "customer", label: "Customer messaging." },
        { enabled: true,  value: "supplier", label: "Supplier messaging." },
        { enabled: true,  value: "system", label: "System messaging." },
        { enabled: true,  value: "adds", label: "Adds on messenger." }
    ]

    // Handle option
    const handleOption = item => {
        console.log(item)
    }

    return (
        <div>
            {options && options.map((item, i) =>
                <Form.Check
                    key={i}
                    type="switch"
                    className="mb-3"
                    style={{ fontSize: 14 }}
                    id={`${item.label}-switch`}
                    label={item.label}
                    defaultChecked={item.enabled}
                    onChange={() => handleOption(item)}
                />
            )}
        </div>
    );
}

export default Index;