import React, { useState } from 'react'
import { X } from 'react-feather'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { GrayButton, PrimaryButton } from '../../../button/Index'

const Create = (props) => {
    const { t } = useTranslation()
    const { register, handleSubmit, formState: { errors } } = useForm()
    const [isMatch, setMatch] = useState(null)

    // Submit Form
    const onSubmit = async (data) => {
        if (data.newPassword !== data.rePassword) return setMatch("Password doesn't match.")
        setMatch(null)
        props.submit(data.newPassword)
    }

    return (
        <Modal
            show={props.show}
            size="md"
            centered
            className="custom-modal"
            onHide={props.onHide}
        >
            <Modal.Header>
                <div className="d-flex">
                    <div><h6 className="mb-0">{t('Change Password')}</h6></div>
                    <div className="ml-auto">
                        <GrayButton
                            type="button"
                            onClick={props.onHide}
                            style={{ padding: "7px 10px", borderRadius: "50%" }}
                        ><X size={16} /></GrayButton>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>

                    {/* New Password */}
                    <div className="form-group mb-4">
                        {errors.newPassword && errors.newPassword.message ?
                            <small className="text-danger">{errors.newPassword && errors.newPassword.message}</small>
                            : <small>{t('New password')}</small>}

                        <input
                            type="password"
                            className={errors.password ? "form-control shadow-none error" : "form-control shadow-none"}
                            placeholder="Enter new password"
                            {...register("newPassword", {
                                required: t("New password is required"),
                                minLength: {
                                    value: 8,
                                    message: t("Minimun length 8 character")
                                }
                            })}
                        />
                    </div>

                    {/* Re-Password */}
                    <div className="form-group mb-4">
                        {errors.rePassword && errors.rePassword.message ?
                            <small className="text-danger">{errors.rePassword && errors.rePassword.message}</small>
                            : isMatch ? <small className="text-danger">{isMatch}</small>
                                : <small>{t('Re-type password')}</small>}

                        <input
                            type="password"
                            className={errors.rePassword ? "form-control shadow-none error" : "form-control shadow-none"}
                            placeholder="Enter password"
                            {...register("rePassword", {
                                required: t("Re-type your password"),
                                minLength: {
                                    value: 8,
                                    message: t("Minimun length 8 character")
                                }
                            })}
                        />
                    </div>

                    <div className="text-right">
                        <PrimaryButton
                            type="submit"
                            className="px-4"
                            disabled={props.loading}
                        >{props.loading ? t("Updating ...") : t("Update")}</PrimaryButton>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default Create;