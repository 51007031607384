import React from 'react'
import './style.scss'
import {
    PieChart,
    Clipboard,
    Calendar,
    BarChart2,
    Book,
    List,
    BookOpen,
    Layers,
    Tag,
    Settings,
    Anchor,
    Database,
    FilePlus,
    FileMinus,
    Minus,
    Plus,
    Users,
    Truck,
    Tool,
    DollarSign,
    BarChart,
    ShoppingCart,
    BatteryCharging,
    MessageCircle,
    ShoppingBag,
    Mail,
    Code,
    User, Clock
} from 'react-feather'
import { TreeMenu } from '../drawerMenu/Index'
import { useTranslation } from 'react-i18next'

const Index = (props) => {
    const { t } = useTranslation()

    // Route paths
    const routes = [
        {
            title: `${t('Dashboard')}`,
            icon: <PieChart size={16} />,
            path: "/dashboard"
        },
        {
            title: `${t('Accounting')}`,
            icon: <Book size={16} />,
            path: "/dashboard/accounting"
        },
        {
            title: `${t('Customer Management')}`,
            icon: <Users size={16} />,
            path: "/dashboard/customer"
        },
        {
            title: `${t('Due Management')}`,
            icon: <BookOpen size={16} />,
            path: "/dashboard/due-management"
        },
        {
            title: `${t('E-commerce')}`,
            icon: <ShoppingCart size={16} />,
            path: "/dashboard/ecommerce"
        },
        {
            title: `${t('Employee Management')}`,
            icon: <User size={16} />,
            // path: "/dashboard/employee-management",
            children: [
                {
                    title: `${t('Employee List')}`,
                    icon: <List size={16} />,
                    path: "/dashboard/employee-management/list"
                },
                {
                    title: `${t('Employee Attendance')}`,
                    icon: <Anchor size={16} />,
                    path: "/dashboard/employee-management/attendance"
                },
                {
                    title: `${t('Employee overtime')}`,
                    icon: <Clock size={16} />,
                    path: "/dashboard/employee-management/overtime"
                },
                {
                    title: `${t('Employee salary')}`,
                    icon: <DollarSign size={16} />,
                    path: "/dashboard/employee-management/salary"
                },
                {
                    title: `${t('Employee attendance report')}`,
                    icon: <BarChart2 size={16} />,
                    path: "/dashboard/employee-management/attendance-report"
                }
            ]
        },
        {
            title: `${t('Inventory Management')}`,
            icon: <Clipboard size={16} />,
            children: [
                {
                    title: `${t('Product')}`,
                    icon: <PieChart size={16} />,
                    children: [
                        {
                            title: `${t('Product List')}`,
                            icon: <List size={16} />,
                            path: "/dashboard/inventory/product/list"
                        },
                        {
                            title: `${t('Product category')}`,
                            icon: <BookOpen size={16} />,
                            path: "/dashboard/inventory/product/category"
                        },
                        {
                            title: `${t('Product Brands')}`,
                            icon: <Layers size={16} />,
                            path: "/dashboard/inventory/product/brands"
                        },
                        {
                            title: `${t('Add New Product')}`,
                            icon: <Tag size={16} />,
                            path: "/dashboard/inventory/product/new"
                        }
                    ]
                },
                {
                    title: `${t('Stock')}`,
                    icon: <Anchor size={16} />,
                    children: [
                        {
                            title: `${t('Stock Status')}`,
                            icon: <Database size={16} />,
                            path: "/dashboard/inventory/stock/status"
                        },
                        {
                            title: `${t('Stock In History')}`,
                            icon: <FilePlus size={16} />,
                            path: "/dashboard/inventory/stock/in-history"
                        },
                        {
                            title: `${t('Stock Out History')}`,
                            icon: <FileMinus size={16} />,
                            path: "/dashboard/inventory/stock/out-history"
                        },
                        {
                            title: `${t('Stock Out')}`,
                            icon: <Minus size={16} />,
                            path: "/dashboard/inventory/stock/out"
                        },
                        {
                            title: `${t('Add Stock')}`,
                            icon: <Plus size={16} />,
                            path: "/dashboard/inventory/stock/new"
                        }
                    ]
                },
                {
                    title: `${t('Suppliers')}`,
                    icon: <Users size={16} />,
                    children: [
                        {
                            title: `${t('Supplier List')}`,
                            icon: <List size={16} />,
                            path: "/dashboard/inventory/supplier/list"
                        }
                    ]
                }
            ]
        },
        {
            title: `${t('IMEI/Separate Code Management')}`,
            icon: <Code size={16} />,
            path: "/dashboard/imei"
        },
        {
            title: `${t('Installment Management')}`,
            icon: <BatteryCharging size={16} />,
            path: "/dashboard/installment-management"
        },
        {
            title: `${t('Messaging / Messenger')}`,
            icon: <MessageCircle size={16} />,
            path: "/dashboard/messaging"
        },
        {
            title: `${t('Mechanic & Servicing')}`,
            icon: <Tool size={16} />,
            path: "/dashboard/mechanic-and-servicing"
        },
        {
            title: `${t('POS')}`,
            icon: <Database size={16} />,
            children: [
                {
                    title: `${t('Point of Sale')}`,
                    icon: <Calendar size={16} />,
                    path: "/dashboard/pos/"
                },
                {
                    title: `${t('Customers')}`,
                    icon: <Users size={16} />,
                    path: "/dashboard/pos/customers"
                },
                {
                    title: `${t('Orders')}`,
                    icon: <Truck size={16} />,
                    path: "/dashboard/pos/orders"
                },
                {
                    title: `${t('Servicing')}`,
                    icon: <Tool size={16} />,
                    path: "/dashboard/pos/servicing"
                }
            ]
        },
        {
            title: `${t('Product Return & Replacement')}`,
            icon: <ShoppingBag size={16} />,
            path: "/dashboard/product-return-replacement"
        },
        {
            title: `${t('SMS')}`,
            icon: <Mail size={16} />,
            path: "/dashboard/sms"
        },
        {
            title: `${t('Reports')}`,
            icon: <BarChart2 size={16} />,
            children: [
                {
                    title: `${t('Customer Report')}`,
                    icon: <Users size={16} />,
                    path: "/dashboard/reports/customers"
                },
                {
                    title: `${t('Purchase & Due')}`,
                    icon: <DollarSign size={16} />,
                    path: "/dashboard/reports/purchase-due"
                },
                {
                    title: `${t('Revenue Report')}`,
                    icon: <BarChart size={16} />,
                    path: "/dashboard/reports/revenue"
                },
                {
                    title: `${t('Sales Report')}`,
                    icon: <DollarSign size={16} />,
                    path: "/dashboard/reports/sales"
                },
                {
                    title: `${t('Service Revenue Report')}`,
                    icon: <Tool size={16} />,
                    path: "/dashboard/reports/service-revenue"
                },
                {
                    title: `${t('Stock Report')}`,
                    icon: <BarChart2 size={16} />,
                    path: "/dashboard/reports/stock"
                }
            ]
        },
        {
            title: `${t('Settings')}`,
            icon: <Settings size={16} />,
            path: "/dashboard/settings"
        }
    ]

    return (
        <div>
            <div className="drawer-container">
                <div
                    onClick={props.onHide}
                    className={props.show ? "backdrop d-lg-none open-backdrop" : "backdrop d-lg-none"}
                />

                {/* Drawer */}
                <div className={props.show ? "drawer open-drawer" : "drawer"}>
                    <div className="drawer-body">
                        <TreeMenu options={routes} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index;
