import React, { useState } from 'react'
import { X } from 'react-feather'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { GrayButton, PrimaryButton } from '../../../button/Index'
import SingleSelect from '../../../select/Single'

const Create = (props) => {
    const { t } = useTranslation()
    const { register, handleSubmit, formState: { errors } } = useForm()
    const [role, setRole] = useState({
        options: [
            { label: "Admin", value: "Admin" },
            { label: "Stuff", value: "Stuff" },
        ],
        value: null,
        error: false
    })

    // Submit Form
    const onSubmit = async (data) => {
        if (!role.value) return setRole({ ...role, error: "Role is required." })
        props.submit({ ...data, role: role.value })
    }

    return (
        <Modal
            show={props.show}
            size="md"
            centered
            className="custom-modal"
        >
            <Modal.Header>
                <div className="d-flex">
                    <div><h6 className="mb-0">{t('Create Member')}</h6></div>
                    <div className="ml-auto">
                        <GrayButton
                            type="button"
                            onClick={props.onHide}
                            style={{ padding: "7px 10px", borderRadius: "50%" }}
                        ><X size={16} /></GrayButton>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>

                    {/* Name */}
                    <div className="form-group mb-4">
                        {errors.name && errors.name.message ?
                            <small className="text-danger">{errors.name && errors.name.message}</small>
                            : <small>{t('Name')}</small>}

                        <input
                            type="text"
                            className={errors.name ? "form-control shadow-none error" : "form-control shadow-none"}
                            placeholder="Member name"
                            {...register("name", { required: t("Name is required") })}
                        />
                    </div>

                    {/* E-mail / Phone */}
                    <div className="form-group mb-4">
                        {errors.emailPhone && errors.emailPhone.message ?
                            <small className="text-danger">{errors.emailPhone && errors.emailPhone.message}</small>
                            : <small>{t('E-mail / Phone number')}</small>}

                        <input
                            type="text"
                            className={errors.emailPhone ? "form-control shadow-none error" : "form-control shadow-none"}
                            placeholder="example@gmail.com / 01XXXXXXXXX"
                            {...register("emailPhone", { required: t("E-mail / Phone is required") })}
                        />
                    </div>

                    {/* Role */}
                    <div className="form-group mb-4">
                        {role.error ?
                            <small className="text-danger">{role.error}</small>
                            : <small>{t('Role')}</small>}

                        <SingleSelect
                            placeholder="member role"
                            error={role.error}
                            options={role.options}
                            value={event => setRole({ ...role, value: event.value, error: false })}
                        />
                    </div>

                    <div className="text-right">
                        <PrimaryButton
                            type="submit"
                            className="px-4"
                            disabled={props.loading}
                        >{props.loading ? t("Creating ...") : t("Create")}</PrimaryButton>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default Create;