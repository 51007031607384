import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const Index = () => {
    const { t } = useTranslation()
    const [activated, setActivated] = useState(null)

    const [options] = useState([
        { enabled: true, value: "pos", label: "POS" },
        { enabled: false, value: "A1", label: "A1" },
        { enabled: false, value: "A4", label: "A4" },
        { enabled: false, value: "A5", label: "A5" }
    ])

    useEffect(() => {
        let isActive = null
        if (options && options.length) {
            for (let i = 0; i < options.length; i++) {
                const element = options[i]
                if (element.enabled) isActive = element.value
            }
        }
        setActivated(isActive)
    }, [options])

    // Check activated
    const checkActivated = item => {
        if (item === activated) return true
        return false
    }

    // Handle option
    const handleOption = item => {
        setActivated(item.value)
        console.log(item)
    }

    return (
        <div>
            <h6 className="mb-3" style={{ fontSize: 14 }}><b>Invoice size</b></h6>
            {options && options.map((item, i) =>
                <Form.Check
                    key={i}
                    type="checkbox"
                    name="checkboxvx"
                    label={t(item.label)}
                    style={{ fontSize: 14, marginBottom: 15 }}
                    checked={checkActivated(item.value)}
                    onChange={() => handleOption(item)}
                />
            )}
        </div>
    );
}

export default Index;