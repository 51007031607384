import {divisions, districts, upzilas, postcodes} from "./BDLocation/locations";

export const location = (zip) => {
    let language = localStorage.getItem('language') === 'bn'? true : false
    let address = {
        district: "",
        district_id: "",
        division: "",
        division_id: "",
        postCode: "",
        postOffice: ""

    }
    postcodes.map((item)=>{
        if (item.postCode === zip || item.postOffice === zip){
            address = item
        }
        return address
    })
    if (address?.postOffice){
        divisions.map((item)=>{
            if (item.id === address.division_id){
                if(language){
                    address.division = item.bn_name
                } else {
                    address.division = item.name
                }
            }
            return address
        })
        districts.map((item)=>{
            if (item.id === address.district_id){
                if(language){
                    address.district = item.bn_name
                } else {
                    address.district = item.name
                }
            }
            return address
        })
        upzilas.map((item)=>{
            if (item.name === address.upazila){
                if(language){
                    address.upazila = item.bn_name
                } else {
                    address.upazila = item.name
                }
            }
            if (item.name === address.postOffice){
                if(language){
                    address.postOffice = item.bn_name
                } else {
                    address.postOffice = item.name
                }
            }
            return address
        })
    }
    return address
}
