import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import swal from 'sweetalert'
import { PrimaryButton } from '../../button/Index'

let stepOneData = null

// ---- Step One ----
const Step1 = (props) => {
    const { t } = useTranslation()
    const { register, handleSubmit, formState: { errors } } = useForm()
    const shop_types = [
        "Babies Toys Shop",
        "Bakery & Sweets",
        "Cloth Shop",
        "Computer Shop",
        "Construction Products",
        "Cosmetics Shop",
        "Electronics Shop",
        "Electronics Showroom",
        "Fittings & Sanitary",
        "Furniture Shop",
        "Gift Shop",
        "Glass",
        "Grocery store",
        "Home Appliance",
        "Jewellery",
        "kitchenware",
        "Library & Stationary",
        "Mobile Servicing",
        "Mobile Shop",
        "Paint & Hardware Shop",
        "Pharmacy",
        "Restaurant",
        "Shoe Shop",
        "Steel",
        "tailor",
        "Tiles Shop",
        "Watches Shop",
        "Wholesale",
    ]

    // Submit for next step
    const onSubmit = async (data) => {
        stepOneData = data
        props.nextStep()
    }

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>

                {/* Shop Name */}
                <div className="form-group mb-4">
                    {errors.shopName && errors.shopName.message ?
                        <small className="text-danger">{errors.shopName && errors.shopName.message}</small>
                        : <small style={{ textTransform: 'capitalize' }}>{t('shop name')}</small>}

                    <input
                        type="text"
                        defaultValue={props.shop ? props.shop.name : null}
                        className={errors.emailPhone ? "form-control shadow-none error" : "form-control shadow-none"}
                        {...register("shopName", { required: t('Shop name is required') })}
                        placeholder={t('Enter shop name')}
                    />
                </div>

                {/* Shop type */}
                <div className="form-group mb-4">
                    {errors.shopType && errors.shopType.message ?
                        <small className="text-danger">{errors.shopType && errors.shopType.message}</small>
                        : <small>{t('Shop Type')}</small>}

                    <select
                        className="form-control shadow-none"
                        {...register("shopType", { required: t('Shop type is required') })}
                    >
                        <option value="">{t('Please select an option')}</option>
                        {shop_types && shop_types.map((item, i) =>
                            <option key={i} value={item}>{t(item)}</option>
                        )}
                    </select>
                </div>

                {/* Next step */}
                <div className="text-right">
                    <PrimaryButton
                        type="submit"
                        style={{ padding: "8px 30px", borderRadius: "4px" }}
                    >{t('Next')}</PrimaryButton>
                </div>
            </form>
        </div>
    );
}

// ---- Step Two ----
const Step2 = (props) => {
    const { t } = useTranslation()
    const [isLoading, setLoading] = useState(false)
    const { register, handleSubmit, formState: { errors } } = useForm()
    const setting = [
        "Accounting",
        "Customer Management",
        "Due Management",
        "E-COMMERCE",
        "Employee Management",
        "Inventory Management",
        "IMEI/Separate Code Management",
        "Installment Management",
        "Messaging/Messenger",
        "Mechanic & Servicing",
        "POS",
        "Product Return & Replacement",
        "Reports",
        "SMS"
    ]

    
    // Submit data
    const onSubmit = async (data) => {
        try {
            const shopData = {
                ...stepOneData,
                ...data
            }

            setLoading(true)
            console.log(shopData)

            setTimeout(() => {
                setLoading(false)
                props.onHide()
                swal({
                    title: "Successfully!",
                    text: `${shopData.shopName} shop created.`,
                    icon: "success",
                    button: false,
                })
            }, 1500)
        } catch (error) {
            if (error) console.log(error)
        }
    }

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                {errors.shopFeatures && errors.shopFeatures.message ?
                    <h6 className="text-danger mb-4">{errors.shopFeatures && errors.shopFeatures.message}</h6>
                    : <h6 className="mb-4">{t("Please choose one or more feature")}</h6>}

                {setting && setting.map((item, i) =>
                    <div key={i} className="mb-3">
                        <Form.Check
                            custom
                            type="checkbox"
                            id={`custom-${item}`}
                            label={t(item)}
                            value={item}
                            style={{ fontSize: 14 }}
                            {...register("shopFeatures", { required: t("Please choose one or more feature") })}
                        />
                    </div>
                )}

                {/* Submit data */}
                <div className="text-right">
                    <PrimaryButton
                        type="submit"
                        disabled={isLoading}
                        style={{ padding: "4px 30px", borderRadius: "4px" }}
                    >{props.shop ? isLoading ? t("Updating ...") : t("Update") : isLoading ? t("Creating ...") : t("Create")}</PrimaryButton>
                </div>
            </form>
        </div>
    );
}

export { Step1, Step2 };
