import React, { useState } from 'react'
import { X } from 'react-feather'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { GrayButton, PrimaryButton } from '../../button/Index'
import { FileUploader } from '../../fileUploader/Index'
import { isValidEmail, isValidPhone } from '../../../utils/_heplers'
import { location } from "../../../utils/_locationHelper";

const Create = (props) => {
    const { t } = useTranslation()
    const { register, handleSubmit, formState: { errors } } = useForm()
    const [nidScan, setNidScan] = useState(null)
    const [image, setImage] = useState(null)
    const [moreInfo, setMoreInfo] = useState(false)
    const [postOffice, setPostOffice] = useState('')
    const [upazila, setUpazila] = useState('')
    const [district, setDistrict] = useState('')
    const [division, setDivision] = useState('')

    // Submit Form
    const onSubmit = data => {
        const formData = {
            ...data,
            nidScanCopy: nidScan,
            image: image
        }

        props.submit(formData)
    }
    const changeLocation = (event) => {
        if(event.target.value.length > 3){
            let address = location(event.target.value)
            setDistrict(address.district)
            setDivision(address.division)
            setUpazila(address.upazila)
            setPostOffice(address.postOffice)
        }
    }

    return (
        <Modal
            show={props.show}
            size="lg"
            centered
            className="custom-modal"
            onHide={props.onHide}
        >
            <Modal.Header>
                <div className="d-flex">
                    <div><h6 className="mb-0">{t('Create Customer')}</h6></div>
                    <div className="ml-auto">
                        <GrayButton
                            type="button"
                            onClick={props.onHide}
                            style={{ padding: "7px 10px", borderRadius: "50%" }}
                        ><X size={16} /></GrayButton>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">

                        {/* Name */}
                        <div className="col-12 col-lg-6">
                            <div className="form-group mb-4">
                                {errors.name && errors.name.message ?
                                    <small className="text-danger">{errors.name && errors.name.message}</small>
                                    : <small>{t('Name')}</small>}

                                <input
                                    type="text"
                                    className={errors.name ? "form-control shadow-none error" : "form-control shadow-none"}
                                    placeholder={t("Enter name")}
                                    {...register("name", { required: t("Name is required") })}
                                />
                            </div>
                        </div>

                        {/* Phone */}
                        <div className="col-12 col-lg-6">
                            <div className="form-group mb-4">
                                {errors.phone && errors.phone.message ?
                                    <small className="text-danger">{errors.phone && errors.phone.message}</small>
                                    : <small>{t('Phone')}</small>}

                                <input
                                    type="text"
                                    className={errors.phone ? "form-control shadow-none error" : "form-control shadow-none"}
                                    placeholder="01XXXXXXXXX"
                                    {...register("phone", {
                                        required: t("Phone is required"),
                                        pattern: {
                                            value: isValidPhone(),
                                            message: t("Invalid phone number")
                                        }
                                    })}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-12">
                            <div className="form-group mb-4">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={moreInfo?true:false}
                                        onChange={()=>{setMoreInfo(!moreInfo)}}
                                        style={{cursor: 'pointer'}} id="flexCheckDefault"
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault" style={{cursor: 'pointer'}}>
                                        {t("More info of customer for extra security")}
                                    </label>
                                </div>
                            </div>
                        </div>

                        {moreInfo?
                        <div className="col-12">
                            <div className="row">
                                {/* Purchase */}
                                <div className="col-12 col-lg-6">
                                    <div className="form-group mb-4">
                                        <small>{t('Earlier Purchase')}</small>

                                        <input
                                            type="number"
                                            className="form-control shadow-none"
                                            placeholder={t("Enter purchase amount")}
                                            {...register("purchase")}
                                        />
                                    </div>
                                </div>

                                {/* Due */}
                                <div className="col-12 col-lg-6">
                                    <div className="form-group mb-4">
                                        <small>{t('Earlier Due')}</small>

                                        <input
                                            type="number"
                                            className="form-control shadow-none"
                                            placeholder={t("Enter due amount")}
                                            {...register("due")}
                                        />
                                    </div>
                                </div>
                                {/* E-mail */}
                                <div className="col-12 col-lg-6">
                                    <div className="form-group mb-4">
                                        {errors.email && errors.email.message ?
                                            <small className="text-danger">{errors.email && errors.email.message}</small>
                                            : <small>{t('E-mail')}</small>}

                                        <input
                                            type="text"
                                            className={errors.email ? "form-control shadow-none error" : "form-control shadow-none"}
                                            placeholder="example@gmail.com"
                                            {...register("email", {
                                                required: false,
                                                pattern: {
                                                    value: isValidEmail(),
                                                    message: t("Invalid email address")
                                                }
                                            })}
                                        />
                                    </div>
                                </div>

                                {/* NID */}
                                <div className="col-12 col-lg-6">
                                    <div className="form-group mb-4">
                                        {errors.nid && errors.nid.message ?
                                            <small className="text-danger">{errors.nid && errors.nid.message}</small>
                                            : <small>{t('NID')}</small>}

                                        <input
                                            type="text"
                                            className={errors.nid ? "form-control shadow-none error" : "form-control shadow-none"}
                                            placeholder={t("Enter NID")}
                                            {...register("nid")}
                                        />
                                    </div>
                                </div>

                                {/* NID Scan file upload */}
                                <div className="col-12 col-lg-6">
                                    <FileUploader
                                        width={80}
                                        height={80}
                                        limit={100}
                                        title={t("NID Scan copy")}
                                        dataHandeller={(data) => setNidScan(data.image)}
                                    />
                                </div>

                                {/* Image upload */}
                                <div className="col-12 col-lg-6">
                                    <FileUploader
                                        width={80}
                                        height={80}
                                        limit={100}
                                        title={t("Image")}
                                        dataHandeller={(data) => setImage(data.image)}
                                    />
                                </div>

                                {/* Note */}
                                <div className="col-12">
                                    <div className="form-group mb-4">
                                        <small>{t('Note')}</small>

                                        <textarea
                                            rows={3}
                                            className="form-control shadow-none"
                                            placeholder={t("Write note ...")}
                                            {...register("note")}
                                        />
                                    </div>
                                </div>

                                {/* Street Address */}
                                <div className="col-12 col-lg-6">
                                    <div className="form-group mb-4">
                                        <small>{t('Street address')}</small>

                                        <input
                                            type="text"
                                            className="form-control shadow-none"
                                            placeholder={t("Street address")}
                                            {...register("streetAddress")}
                                        />
                                    </div>
                                </div>

                                {/* ZIP/Post code */}
                                <div className="col-12 col-lg-6">
                                    <div className="form-group mb-4">
                                        <small>{t('ZIP / Post code')}</small>

                                        <input
                                            type="text"
                                            className="form-control shadow-none"
                                            placeholder={t("ZIP / Post code")}
                                            onKeyUp={changeLocation}
                                            {...register("zipPostCode")}
                                        />
                                    </div>
                                </div>

                                {/* Post office */}
                                <div className="col-12 col-lg-6">
                                    <div className="form-group mb-4">
                                        <small>{t('Post office')}</small>

                                        <input
                                            type="text"
                                            className="form-control shadow-none"
                                            placeholder={t("Post office")}
                                            {...register("postOffice")}
                                            value={postOffice}
                                        />
                                    </div>
                                </div>

                                {/* Upzila */}
                                <div className="col-12 col-lg-6">
                                    <div className="form-group mb-4">
                                        <small>{t('Upzila')}</small>

                                        <input
                                            type="text"
                                            className="form-control shadow-none"
                                            placeholder={t("Upzila")}
                                            {...register("upzila")}
                                            value={upazila}
                                        />
                                    </div>
                                </div>

                                {/* District / City */}
                                <div className="col-12 col-lg-6">
                                    <div className="form-group mb-4">
                                        <small>{t('District / City')}</small>

                                        <input
                                            type="text"
                                            className="form-control shadow-none"
                                            placeholder="District / City"
                                            {...register("districtCity")}
                                            value={district}
                                        />
                                    </div>
                                </div>

                                {/* Division / State */}
                                <div className="col-12 col-lg-6">
                                    <div className="form-group mb-4">
                                        <small>{t('Division / State')}</small>

                                        <input
                                            type="text"
                                            className="form-control shadow-none"
                                            placeholder={t("Division / State")}
                                            {...register("divisionState")}
                                            value={division}
                                        />
                                    </div>
                                </div>
                        </div>
                        </div> : null
                        }

                    </div>

                    <div className="text-right">
                        <PrimaryButton
                            type="submit"
                            className="px-4"
                            disabled={props.loading}
                        >{props.loading ? t("Creating ...") : t("Create")}</PrimaryButton>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default Create;
