import React, { useState } from 'react'
import './style.scss'
import { Switch, Route } from 'react-router-dom'

import Navbar from '../../components/navbar/Index'
import Drawer from '../../components/drawer/Index'

// --- Dashboard ---
import DashboardIndex from '../dashboard/Index'

// --- Customer ---
import CustomerIndex from '../customer/Index'
import CustomerShow from '../customer/Show'

// --- Inventory / Product ---
import ProductIndex from '../inventory/product/Index'
import ProductCreate from '../inventory/product/Create'
import CategoryIndex from '../inventory/product/Category'
import BrandIndex from '../inventory/product/Brand'

// // --- Inventory / Stock ---
// import StockStatus from '../inventory/stock/StockStatus'
// import StockIn from '../inventory/stock/StockIn'
// import StockOut from '../inventory/stock/StockOut'

// // --- Inventory / Supplier ---
// import SupplierIndex from '../inventory/supplier/Index'

// // --- POS / Customer ---
// import CustomerIndex from '../pos/customer/Index'

// // --- POS / Order ---
// import OrderIndex from '../pos/order/Index'

// // --- POS / Servicing ---
// import ServicingIndex from '../pos/servicing/Index'

// // --- Reports ---
// import CustomerReport from '../reports/Customer'
// import PurchaseDueReport from '../reports/PurchaseDue'
// import RevenueReport from '../reports/Revenue'
// import SalesReport from '../reports/Sales'
// import ServiceRevenueReport from '../reports/Servicing'
// import StockReport from '../reports/Stock'

// // --- Users ---
// import UsersIndex from '../users/user/Index'
// import MechanicIndex from '../users/mechanic/Index'

// --- Settings ---
import SettingsIndex from '../settings/Index'

// --- Static ---
import TermOfServices from '../termOfService/Index'
import DataPolicy from '../dataPolicy/Index'
import Cookies from '../cookies/Index'
import CommunityStandard from '../communityStandard/Index'
import About from '../about/Index'

import FourOFour from '../fourOfour/Index'

//Employee management
import EmployeeList from "../employeeManagement/EmployeeList";

const Master = () => {
    const [open, setOpen] = useState(false)

    return (
        <div className="master">
            <Navbar menu={true} drawer={() => setOpen(true)} />
            <Drawer show={open} onHide={() => setOpen(false)} />

            <div className="main">
                <Switch>

                    {/* --- Dashboard --- */}
                    <Route exact path="/dashboard/" component={DashboardIndex} />

                    {/* --- Customer --- */}
                    <Route exact path="/dashboard/customer" component={CustomerIndex} />
                    <Route exact path="/dashboard/customer/show/:id" component={CustomerShow} />

                    {/* --- Inventory / Product --- */}
                    <Route exact path="/dashboard/inventory/product/list" component={ProductIndex} />
                    <Route exact path="/dashboard/inventory/product/new" component={ProductCreate} />
                    <Route exact path="/dashboard/inventory/product/category" component={CategoryIndex} />
                    <Route exact path="/dashboard/inventory/product/brands" component={BrandIndex} />

                    {/* --- Inventory / Stock --- */}
                    {/* <Route exact path="/dashboard/inventory/stock/status" component={StockStatus} />
                    <Route exact path="/dashboard/inventory/stock/in-history" component={StockIn} />
                    <Route exact path="/dashboard/inventory/stock/out-history" component={StockOut} /> */}

                    {/* --- Inventory / Supplier --- */}
                    {/* <Route exact path="/dashboard/inventory/supplier/list" component={SupplierIndex} /> */}

                    {/* --- POS / Customer --- */}
                    {/* <Route exact path="/dashboard/pos/customers" component={CustomerIndex} /> */}

                    {/* --- POS / Order --- */}
                    {/* <Route exact path="/dashboard/pos/orders" component={OrderIndex} /> */}

                    {/* --- POS / Servicing --- */}
                    {/* <Route exact path="/dashboard/pos/servicing" component={ServicingIndex} /> */}

                    {/* --- Reports --- */}
                    {/* <Route exact path="/dashboard/reports/customers" component={CustomerReport} />
                    <Route exact path="/dashboard/reports/purchase-due" component={PurchaseDueReport} />
                    <Route exact path="/dashboard/reports/revenue" component={RevenueReport} />
                    <Route exact path="/dashboard/reports/sales" component={SalesReport} />
                    <Route exact path="/dashboard/reports/service-revenue" component={ServiceRevenueReport} />
                    <Route exact path="/dashboard/reports/stock" component={StockReport} /> */}

                    {/* --- Users --- */}
                    {/* <Route exact path="/dashboard/customers" component={UsersIndex} />
                    <Route exact path="/dashboard/mechanics" component={MechanicIndex} /> */}

                    {/* --- Settings --- */}
                    <Route exact path="/dashboard/settings" component={SettingsIndex} />

                    {/* --- Static --- */}
                    <Route exact path="/dashboard/term-of-service" component={TermOfServices} />
                    <Route exact path="/dashboard/data-policy" component={DataPolicy} />
                    <Route exact path="/dashboard/cookies" component={Cookies} />
                    <Route exact path="/dashboard/community-standard" component={CommunityStandard} />
                    <Route exact path="/dashboard/about" component={About} />

                    {/*Employee Management*/}
                    <Route exact path="/dashboard/employee-management/list" component={EmployeeList} />

                    <Route path="*">
                        <FourOFour mt={"-70px"} />
                    </Route>
                </Switch>
            </div>
        </div>
    )
}

export default Master;
