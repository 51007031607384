
import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { DataTable } from '../../../components/table/Index'

const Index = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [totalRows, setTotalRows] = useState(0)
    const [perPage, setPerPage] = useState(10)

    const fetchUsers = useCallback(async (page) => {
        setLoading(true)
        const response = await axios.get(
            `https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`,
        )

        setData(response.data.data)
        setTotalRows(response.data.total)
        setLoading(false)
    }, [perPage])

    const handlePageChange = page => fetchUsers(page)

    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true)
        const response = await axios.get(`https://reqres.in/api/users?page=${page}&per_page=${newPerPage}&delay=1`)

        setData(response.data.data)
        setPerPage(newPerPage)
        setLoading(false)
    }

    useEffect(() => {
        fetchUsers(1)
    }, [fetchUsers])

    const columns = [
        {
            name: 'SL',
            selector: row => row.id,
            sortable: true,
            grow: 0
        },
        {
            name: 'Type of Operation',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Description',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Date & Time',
            selector: row => row.email,
            sortable: true,
        }
    ]

    return (
        <div>
            <DataTable
                columns={columns}
                data={data}
                loading={loading}
                totalRows={totalRows}
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
            />
        </div>
    );
}

export default Index;