export const englishToBengali = (english) => {
    localStorage.getItem('language')
    if (localStorage.getItem('language') === 'bn') {
        english = numberE2B(english)
    }
    return english
};

export const numberE2B = (number) => {
    let bengaliNumber = ['০', '১', '২', '৩', '৪', '৫', '৬', '৭', '৮', '৯']
    let englishNumber = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
    number = number+''
    for (let i = 0; i < englishNumber.length; i++) {
        number = number.replaceAll(englishNumber[i], bengaliNumber[i])
    }
    return number
}

// Phone number valid check
export const isValidPhone = () => {
    const regex = /^(?:\+88|88)?(01[3-9]\d{8})$/i
    return regex
}

// E-mail valid check
export const isValidEmail = () => {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
    return regex
}
