
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { ArrowLeft } from 'react-feather'
import { GrayButton } from '../../../components/button/Index'
import { Layout, Main } from '../../../components/layout/Index'
import ProductForm from '../../../components/form/Product'
import { suppliers, brands, categories } from '../../../utils/data'

const Index = () => {
    const [loading, setLoading] = useState(false)

    // Handle submit
    const handleSubmit = async data => {
        try {
            setLoading(true)

            setTimeout(() => {
                console.log(data)
                setLoading(false)
            }, 1000)
        } catch (error) {
            if (error) {
                setLoading(false)
                console.log(error)
            }
        }
    }

    return (
        <div>
            <Layout
                page="inventory / create product"
                message="Add new product to your shop."
                container="container-fluid"
                button={
                    <div className="print-hidden">
                        <Link to="/dashboard/inventory/product/list">
                            <GrayButton type="button">
                                <ArrowLeft size={15} style={{ marginRight: 5 }} />
                                <span style={{ fontSize: 13 }}>BACK</span>
                            </GrayButton>
                        </Link>
                    </div>
                }
            />

            <Main>
                <div className="col-12">
                    <ProductForm
                        loading={loading}
                        brands={brands}
                        suppliers={suppliers}
                        categories={categories}
                        submit={handleSubmit}
                    />
                </div>
            </Main>
        </div>
    );
}

export default Index;