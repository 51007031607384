
import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import swal from 'sweetalert'
import {
    DangerButton,
    PrimaryButton,
    SuccessButton
} from '../../../components/button/Index'
import { Edit2, Trash2 } from 'react-feather'
import { DataTable } from '../../../components/table/Index'
import CreateMember from '../../modals/settings/member/Create'

const Index = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [searching, setSearching] = useState(false)
    const [isCreate, setCreate] = useState({ show: false, loading: false })

    const fetchUsers = useCallback(async (page) => {
        setLoading(true)
        const response = await axios.get(
            `https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`,
        )

        setData(response.data.data)
        setTotalRows(response.data.total)
        setLoading(false)
    }, [perPage])

    const handlePageChange = page => fetchUsers(page)

    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true)
        const response = await axios.get(`https://reqres.in/api/users?page=${page}&per_page=${newPerPage}&delay=1`)

        setData(response.data.data)
        setPerPage(newPerPage)
        setLoading(false)
    }

    useEffect(() => {
        fetchUsers(1)
    }, [fetchUsers])

    const columns = [
        {
            name: 'Name',
            selector: row => row.first_name,
            sortable: true,
        },
        {
            name: 'E-mail',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Phone',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Role',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Action',
            grow: 0,
            minWidth: '150px',
            cell: row =>
                <div>
                    <SuccessButton
                        style={{ borderRadius: "50%", padding: "6px 9px", marginRight: 5 }}
                        onClick={() => handleAction(row)}
                    >
                        <Edit2 size={16} />
                    </SuccessButton>
                    <DangerButton
                        style={{ borderRadius: "50%", padding: "6px 9px" }}
                        onClick={() => handleAction(row)}
                    >
                        <Trash2 size={16} />
                    </DangerButton>
                </div>
        }
    ]

    // Handle action
    const handleAction = value => console.log(value);

    // Handle search
    const handleSearch = async query => {
        setSearching(true)
        console.log(query)

        setTimeout(() => {
            setSearching(false)
        }, 2000);
    }

    // Handle member create
    const handleCreateMember = async data => {
        try {
            setCreate({ ...isCreate, loading: true })
            console.log(data)

            setTimeout(() => {
                setCreate({ show: false, loading: false })
                swal({
                    title: "Successfully!",
                    text: `Member created.`,
                    icon: "success",
                    button: false,
                })
            }, 1000)
        } catch (error) {
            if (error) console.log(error)
        }
    }

    return (
        <div>
            <div className="text-right mb-3">
                <PrimaryButton
                    type="button"
                    className="px-4"
                    onClick={() => setCreate({ show: true, loading: false })}
                >Add Member</PrimaryButton>
            </div>

            <DataTable
                columns={columns}
                data={data}
                loading={loading}
                totalRows={totalRows}
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
                searchable
                search={handleSearch}
                searching={searching}
            />

            {/* Member Create */}
            <CreateMember
                show={isCreate.show}
                loading={isCreate.loading}
                submit={handleCreateMember}
                onHide={() => setCreate({ show: false, loading: false })}
            />
        </div>
    );
}

export default Index;