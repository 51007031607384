import React, { useState } from 'react'
import swal from 'sweetalert'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { PrimaryButton } from '../../button/Index'
import { isValidEmail, isValidPhone } from '../../../utils/_heplers'

const Index = () => {
    const { t } = useTranslation()
    const { register, handleSubmit, formState: { errors } } = useForm()
    const [isLoading, setLoading] = useState(false)

    // Submit Form
    const onSubmit = async (data) => {
        try {
            setLoading(true)
            console.log(data)

            setTimeout(() => {
                setLoading(false)
                swal({
                    title: "Successfully!",
                    text: `Update your personal information.`,
                    icon: "success",
                    button: false,
                })
            }, 1000)
        } catch (error) {
            if (error) console.log(error)
        }
    }

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>

                {/* Name */}
                <div className="form-group mb-4">
                    {errors.name && errors.name.message ?
                        <small className="text-danger">{errors.name && errors.name.message}</small>
                        : <small>{t('Name')}</small>}

                    <input
                        type="text"
                        className={errors.name ? "form-control shadow-none error" : "form-control shadow-none"}
                        placeholder={t("Enter your name")}
                        {...register("name", { required: t("Name is required") })}
                    />
                </div>

                {/* E-mail */}
                <div className="form-group mb-4">
                    {errors.email && errors.email.message ?
                        <small className="text-danger">{errors.email && errors.email.message}</small>
                        : <small>{t('E-mail')}</small>}

                    <input
                        type="text"
                        className={errors.email ? "form-control shadow-none error" : "form-control shadow-none"}
                        placeholder="example@gmail.com"
                        {...register("email", {
                            required: t("E-mail is required"),
                            pattern: {
                                value: isValidEmail(),
                                message: "Invalid e-mail address"
                            }
                        })}
                    />
                </div>

                {/* Phone */}
                <div className="form-group mb-4">
                    {errors.phone && errors.phone.message ?
                        <small className="text-danger">{errors.phone && errors.phone.message}</small>
                        : <small>{t('Phone')}</small>}

                    <input
                        type="text"
                        className={errors.phone ? "form-control shadow-none error" : "form-control shadow-none"}
                        placeholder="01XXXXXXXXX"
                        {...register("phone", {
                            required: t("Phone number is required"),
                            pattern: {
                                value: isValidPhone(),
                                message: "Invalid phone number"
                            }
                        })}
                    />
                </div>

                {/* NID */}
                <div className="form-group mb-4">
                    {errors.nid && errors.nid.message ?
                        <small className="text-danger">{errors.nid && errors.nid.message}</small>
                        : <small>{t('NID')}</small>}

                    <input
                        type="text"
                        className={errors.nid ? "form-control shadow-none error" : "form-control shadow-none"}
                        placeholder={t("Enter NID")}
                        {...register("nid")}
                    />
                </div>

                <div className="text-right">
                    <PrimaryButton
                        type="submit"
                        className="px-4"
                        disabled={isLoading}
                    >{isLoading ? t("Updating ...") : t("Update")}</PrimaryButton>
                </div>
            </form>
        </div>
    );
}

export default Index;