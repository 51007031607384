import React from 'react'
import { Form } from 'react-bootstrap'

const Index = () => {
    const options = [
        { enabled: true, value: "system", label: "System notification." },
        { enabled: true, value: "sms", label: "SMS notification." },
        { enabled: true, value: "email", label: "E-mail notification." },
        { enabled: true, value: "unseenMessage", label: "Unseen message notification." },
        { enabled: true, value: "ecommerce", label: "E-commerce notification." },
        { enabled: true, value: "purchaseToCustomer", label: "Purchase notification for customer as SMS." }
    ]

    // Handle option
    const handleOption = item => {
        console.log(item)
    }

    return (
        <div>
            {options && options.map((item, i) =>
                <Form.Check
                    key={i}
                    type="switch"
                    className="mb-3"
                    style={{ fontSize: 14 }}
                    id={`${item.label}-switch`}
                    label={item.label}
                    defaultChecked={item.enabled}
                    onChange={() => handleOption(item)}
                />
            )}
        </div>
    );
}

export default Index;