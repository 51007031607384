import React, { useState } from 'react'
import './style.scss'
import { ChevronDown } from 'react-feather'
import { Layout, Main } from '../../components/layout/Index'
import { FloatButton } from '../../components/help/Index'
import { Accordion, Card, Button, ListGroup } from "react-bootstrap"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import General from '../../components/settings/general/Index'
import PersonalInfo from '../../components/settings/personalInfo/Index'
import Location from '../../components/settings/location/Index'
import Member from '../../components/settings/member/Index'
import Notification from '../../components/settings/notification/Index'
import Messaging from '../../components/settings/messaging/Index'
import SecurityLogin from '../../components/settings/securityLogin/Index'
import ActivityLog from '../../components/settings/activityLogs/Index'
import Invoice from '../../components/settings/invoice/Index'
import Measurement from '../../components/settings/measurement/Index'

const Index = () => {
    const { t } = useTranslation()
    const [activeKey, setActiveKey] = useState(null)

    const items = [
        { label: "General", component: <General /> },
        { label: "Personal Info.", component: <PersonalInfo /> },
        { label: "Location", component: <Location /> },
        { label: "Member", component: <Member /> },
        { label: "Notification", component: <Notification /> },
        { label: "Messaging", component: <Messaging /> },
        { label: "Security & Login", component: <SecurityLogin /> },
        { label: "Activity log", component: <ActivityLog /> },
        { label: "Invoice & Printing", component: <Invoice /> },
        { label: "Default Measurement Unit", component: <Measurement /> }
    ]

    const policies = [
        { path: "/dashboard/term-of-service", label: "Terms of Service" },
        { path: "/dashboard/data-policy", label: "Data Policy" },
        { path: "/dashboard/cookies", label: "Cookies Policy" },
        { path: "/dashboard/community-standard", label: "Community Standards" },
        { path: "/dashboard/about", label: "About" }
    ]

    const toggleActive = key => {
        if (activeKey === key) {
            setActiveKey(null)
        } else {
            setActiveKey(key)
        }
    }

    return (
        <div>
            <Layout
                page={t("settings / setting")}
                message={t("Your system setting is here, You can change anytime.")}
                container="container-fluid"
            />

            <Main>
                <div className="col-12 col-xl-8 mb-4 settings-index">
                    <Accordion>
                        {items && items.map((item, i) =>
                            <Card className="rounded-0" key={i}>
                                <Card.Header
                                    className="px-2 bg-white"
                                    onClick={() => toggleActive(i)}
                                >
                                    <Accordion.Toggle as={Button} variant="link" eventKey={i + 1}>
                                        <div className="d-flex">
                                            <div><h6 className="mb-0">{t(item.label)}</h6></div>
                                            <div className="ml-auto"><ChevronDown className={activeKey === i ? "icon down" : "icon"} size={18} /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey={i + 1}>
                                    <Card.Body>
                                        {item.component}
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        )}
                    </Accordion>

                    <br />
                    <ListGroup variant="flush">
                        {policies && policies.map((item, i) =>
                            <ListGroup.Item key={i}>
                                <Link to={item.path}>{t(item.label)}</Link>
                            </ListGroup.Item>
                        )}
                    </ListGroup>
                </div>
            </Main>
            <FloatButton />
        </div>
    );
}

export default Index;