import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const Index = () => {
    const { t } = useTranslation()
    const [activated, setActivated] = useState(null)

    const [options] = useState([
        { enabled: true, value: "Pcs", label: "Pcs" },
        { enabled: false, value: "Dojon", label: "Dojon" },
        { enabled: false, value: "Kg", label: "Kg" },
        { enabled: false, value: "Gram", label: "Gram" },
        { enabled: false, value: "Pound", label: "Pound" },
        { enabled: false, value: "Feet", label: "Feet" },
        { enabled: false, value: "Meter", label: "Meter" },
        { enabled: false, value: "Goj", label: "Goj" },
        { enabled: false, value: "Metric Ton", label: "Metric Ton" },
        { enabled: false, value: "Pair", label: "Pair" },
        { enabled: false, value: "Hali", label: "Hali" },
        { enabled: false, value: "Litter", label: "Litter" },
        { enabled: false, value: "Square Meter", label: "Square Meter" },
        { enabled: false, value: "Square Feet", label: "Square Feet" },
        { enabled: false, value: "Vori", label: "Vori" },
        { enabled: false, value: "Ani", label: "Ani" },
        { enabled: false, value: "Careet", label: "Careet" },
        { enabled: false, value: "Bun", label: "Bun" },
        { enabled: false, value: "Cft", label: "Cft" },
        { enabled: false, value: "Cubic Meter", label: "Cubic Meter" },
        { enabled: false, value: "Cubit Litter", label: "Cubit Litter" }
    ])

    useEffect(() => {
        let isActive = null
        if (options && options.length) {
            for (let i = 0; i < options.length; i++) {
                const element = options[i]
                if (element.enabled) isActive = element.value
            }
        }
        setActivated(isActive)
    }, [options])

    // Check activated
    const checkActivated = item => {
        if (item === activated) return true
        return false
    }

    // Handle option
    const handleOption = item => {
        setActivated(item.value)
        console.log(item)
    }

    return (
        <div>
            <h6 className="mb-3" style={{ fontSize: 14 }}><b>Invoice size</b></h6>
            {options && options.map((item, i) =>
                <Form.Check
                    key={i}
                    type="checkbox"
                    name="checkboxvx"
                    label={t(item.label)}
                    style={{ fontSize: 14, marginBottom: 15 }}
                    checked={checkActivated(item.value)}
                    onChange={() => handleOption(item)}
                />
            )}
        </div>
    );
}

export default Index;