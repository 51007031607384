import React, { useEffect, useState } from 'react'
import './style.scss'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'

import { Images } from '../../utils/Images'
import { PrimaryButton } from '../../components/button/Index'
import { isValidEmail, isValidPhone } from '../../utils/_heplers'
import Image from '../../components/image/Index'

const Login = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const { register, handleSubmit, formState: { errors } } = useForm()
    const [isLogging, setLogging] = useState(false)
    const lang = localStorage.getItem('language')

    useEffect(() => {
        const token = localStorage.getItem('token')
        if (token) history.push('/dashboard')
    }, [history])

    // Submit Form
    const onSubmit = async (data) => {
        setLogging(true)
        console.log(data)
        setLogging(false)
    }

    return (
        <div className="auth-container">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="card shadow-none border-0">
                            <div className="card-header bg-white px-0">
                                <div className="text-center mb-4">
                                    <Image
                                        x={90}
                                        y={90}
                                        src={Images.Logo}
                                        alt="Company Logo"
                                    />
                                </div>
                                <h4 className="mb-2">{t("Create New Account")}</h4>
                                <p className="mb-0">{t("It's free to signup and only takes a minute.")}</p>
                            </div>
                            <div className="card-body px-0">
                                <form onSubmit={handleSubmit(onSubmit)}>

                                    {/* Name */}
                                    <div className="form-group mb-4">
                                        {errors.name && errors.name.message ?
                                            <small className="text-danger">{errors.name && errors.name.message}</small>
                                            : <small style={{ textTransform: "capitalize" }}>{t("name")}</small>}

                                        <input
                                            type="text"
                                            className={errors.name ? "form-control shadow-none error" : "form-control shadow-none"}
                                            placeholder={t("Your name")}
                                            {...register("name", { required: t("Name is required") })}
                                        />
                                    </div>

                                    {/* Phone */}
                                    <div className="form-group mb-4">
                                        {errors.phone && errors.phone.message ?
                                            <small className="text-danger">{errors.phone && errors.phone.message}</small>
                                            : <small style={{ textTransform: "capitalize" }}>{t('phone')}</small>}

                                        <input
                                            type="text"
                                            className={errors.phone ? "form-control shadow-none error" : "form-control shadow-none"}
                                            placeholder="01xxxxxxxxx"
                                            {...register("phone", {
                                                required: t("Phone number is required"),
                                                pattern: {
                                                    value: isValidPhone(),
                                                    message: "Invalid phone number"
                                                }
                                            })}
                                        />
                                    </div>

                                    {/* E-mail */}
                                    <div className="form-group mb-4">
                                        {errors.email && errors.email.message ?
                                            <small className="text-danger">{errors.email && errors.email.message}</small>
                                            : <small>{t("E-mail")}</small>}

                                        <input
                                            type="text"
                                            className={errors.email ? "form-control shadow-none error" : "form-control shadow-none"}
                                            placeholder="example@gmail.com"
                                            {...register("email", {
                                                required: false,
                                                pattern: {
                                                    value: isValidEmail(),
                                                    message: "Invalid e-mail address"
                                                }
                                            })}
                                        />
                                    </div>

                                    {/* Password */}
                                    <div className="form-group mb-4">
                                        {errors.password && errors.password.message ?
                                            <small className="text-danger">{errors.password && errors.password.message}</small>
                                            : <small>{t("Password")}</small>}

                                        <input
                                            type="password"
                                            placeholder={t("Enter your password")}
                                            className={errors.password ? "form-control shadow-none error" : "form-control shadow-none"}
                                            {...register("password", { required: "Password is required" })}
                                        />
                                    </div>

                                    {/* Confirm Password */}
                                    <div className="form-group mb-4">
                                        {errors.cPassword && errors.cPassword.message ?
                                            <small className="text-danger">{errors.cPassword && errors.cPassword.message}</small>
                                            : <small>{t("Confirm Password")}</small>}

                                        <input
                                            type="password"
                                            placeholder={t("Enter your password confirmation")}
                                            className={errors.cPassword ? "form-control shadow-none error" : "form-control shadow-none"}
                                            {...register("cPassword", { required: t("Confirm password is required") })}
                                        />
                                    </div>

                                    {/* Message */}
                                    <div className="message-text">
                                        {lang === 'en' ?
                                            <p>By clicking <strong>Create an account</strong> below, you agree to our <Link to="/terms">terms of service</Link> and <Link to="/privacy">privacy statement</Link>.</p>
                                            :
                                            <p>নিচের <strong>{t("Create Account")}</strong> ক্লিক করায়, আপনি আমাদের <Link to="/terms">সেবা পাবার শর্ত</Link> এবং <Link to="/privacy">গোপনীয়তা বিবৃতি</Link> এর সাথে একমত হচ্ছেন।</p>
                                        }
                                    </div>

                                    <PrimaryButton
                                        type="submit"
                                        style={{ width: "100%" }}
                                        disabled={isLogging}
                                    >{isLogging ? t("Create Account") + ' ...' : t("Create Account")}</PrimaryButton>

                                    <div className="divider-text">{t('or')}</div>
                                    <div className="text-center">
                                        <p>{t("Already have an account?")} <Link to="/">{t("Sign In")}</Link></p>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
